import React from 'react';
import ReactDOM from 'react-dom';
import 'tailwindcss/tailwind.css'; // Ensure you have Tailwind CSS set up
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome
import { Link, Element } from 'react-scroll'; // Import Link and Element from react-scroll

const App = () => {
    return (
        <div name="get-started" className="bg-white text-gray-900 font-poppins">
            {/* Fixed Navbar */}
            <header className="fixed top-0 left-0 right-0 bg-white shadow-md z-50">
                <div className="flex justify-between items-center p-4 border-b">
                    <div className="flex items-center">
                        <i className="fas fa-comment-dots text-green-500"></i>
                        <span className="ml-2 font-bold">MitDev AI Bot</span>
                    </div>
                    <nav className="space-x-6">
                        <Link to="features" smooth={true} duration={500} className="text-gray-600 hover:text-gray-900">Features</Link>
                        <Link to="why" smooth={true} duration={500} className="text-gray-600 hover:text-gray-900">Benefits</Link>
                        <Link to="get-started" smooth={true} duration={500} className="text-gray-600 hover:text-gray-900">Start</Link>
                    </nav>
                </div>
            </header>

            {/* Main Content */}
            <main className="pt-20 text-center py-16 px-4 sm:px-6 lg:px-8">
                <h1 className="text-4xl font-bold mb-4 mt-20">Supercharge Your WhatsApp with <span className="text-green-500">AI</span></h1>
                <p className="text-gray-600 mb-8">MitDev AI Bot brings the power of artificial intelligence to your WhatsApp conversations, making communication smarter, faster, and more efficient.</p>
                <a href="https://wa.me/6283875765821" className="bg-black text-white py-2 px-4 rounded-full hover:bg-gray-800 transition duration-300 ease-in-out">
    Start Using MitDev AI Bot <i className="fas fa-arrow-right ml-2"></i>
</a>

            </main>

            {/* Features Section */}
            <Element name="features" className="py-16 bg-gray-50">
                <h2 className="text-2xl font-bold text-center mb-8">Key Features</h2>
                <div className="max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 px-4 sm:px-6 lg:px-8">
                    <div className="bg-white p-6 rounded-lg shadow-md text-center transform transition-transform duration-300 hover:scale-105">
                        <i className="fas fa-comments text-2xl mb-4"></i>
                        <h3 className="font-bold mb-2">Natural Language Processing</h3>
                        <p className="text-gray-600">Understand and respond to messages in natural language, making conversations feel more human-like.</p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-md text-center transform transition-transform duration-300 hover:scale-105">
                        <i className="fas fa-bolt text-2xl mb-4"></i>
                        <h3 className="font-bold mb-2">Quick Responses</h3>
                        <p className="text-gray-600">Generate instant replies to common queries, saving time and improving efficiency.</p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-md text-center transform transition-transform duration-300 hover:scale-105">
                        <i className="fas fa-lock text-2xl mb-4"></i>
                        <h3 className="font-bold mb-2">Secure Conversations</h3>
                        <p className="text-gray-600">End-to-end encryption ensures your conversations remain private and secure.</p>
                    </div>
                </div>
            </Element>

            {/* Why Choose Section */}
            <Element name="why" className="flex flex-col items-center justify-center min-h-screen p-4">
                <h1 className="text-3xl font-bold mb-8">Why Choose MitDev AI Bot?</h1>
                <div className="flex flex-wrap justify-around w-full max-w-4xl mb-16">
                    <div className="w-full md:w-1/2 mb-4">
                        <h2 className="text-xl font-semibold mb-4">For Businesses</h2>
                        <ul className="list-none space-y-2">
                            <li className="flex items-center">
                                <i className="fas fa-check text-green-500 mr-2"></i>
                                Improve customer support efficiency
                            </li>
                            <li className="flex items-center">
                                <i className="fas fa-check text-green-500 mr-2"></i>
                                Automate repetitive tasks
                            </li>
                            <li className="flex items-center">
                                <i className="fas fa-check text-green-500 mr-2"></i>
                                24/7 availability for customer inquiries
                            </li>
                        </ul>
                    </div>
                    <div className="w-full md:w-1/2 mb-4">
                        <h2 className="text-xl font-semibold mb-4">For Individuals</h2>
                        <ul className="list-none space-y-2">
                            <li className="flex items-center">
                                <i className="fas fa-check text-green-500 mr-2"></i>
                                Get quick answers to your questions
                            </li>
                            <li className="flex items-center">
                                <i className="fas fa-check text-green-500 mr-2"></i>
                                Enhance personal productivity
                            </li>
                            <li className="flex items-center">
                                <i className="fas fa-check text-green-500 mr-2"></i>
                                Learn new things through interactive conversations
                            </li>
                        </ul>
                    </div>
                </div>
                <h2 className="text-2xl font-bold mb-4">Ready to Transform Your WhatsApp Experience?</h2>
                <p className="text-gray-600 mb-8 text-center">
                    Join thousands of users who have already supercharged their WhatsApp with MitDev AI Bot. It's free to get started!
                </p>
                <div className="flex flex-wrap justify-center space-x-4 mb-16">
    <a href="https://wa.me/6283875765821" target="_blank" rel="noopener noreferrer" className="bg-black text-white py-2 px-4 rounded-full flex items-center mb-2 transition duration-300 ease-in-out">
        <i className="fab fa-whatsapp mr-2"></i>
        Add to WhatsApp
    </a>


                </div>
                <footer className="text-center text-gray-500 text-sm">
                    <p>© 2024 MutiatDev. All rights reserved.</p>
                    <div className="flex justify-center space-x-4 mt-2">
                        <a href="#" className="">Privacy Policy</a>
                        <a href="#" className="">Terms of Service</a>
                        <a href="#" className="">Contact Us</a>
                    </div>
                </footer>
            </Element>
        </div>
    );
};

export default App;

// In your index.js or main entry file, make sure to import ReactDOM and render the App component
// import ReactDOM from 'react-dom';
// import App from './App';
// ReactDOM.render(<App />, document.getElementById('root'));
